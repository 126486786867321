export const slugify = (string) =>
  string
    // Decompose special characters
    .normalize('NFKD')
    // Remove diacritical marks
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    // Replace all special characters with a space
    .replace(/[^a-z0-9]+/g, ' ')
    .trim()
    // Replace all spaces with only one space
    .replace(/\s+/g, '-')
