<template>
  <b-collapse animation="slide" aria-id="contentIdForA11y1" :open="false">
    <template #trigger="props">
      <div
        aria-controls="contentIdForA11y1"
        :aria-expanded="props.open"
        role="button"
        class="card-header has-background-white-bis"
      >
        <h2 class="card-header-title">Messages de configuration d'alerte</h2>
        <div class="card-header-icon">
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
        </div>
      </div>
    </template>
    <div class="has-background-white-ter p-4 content">
      <div class="columns is-multiline">
        <b-field v-for="message in alertMessages" :key="message" class="column is-full-mobile">
          <b-input :value="message" readonly expanded />
          <p class="control">
            <copy-button :text-to-copy="message" />
          </p>
        </b-field>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import {
  ALERT_TYPE_MEDICAL_HELP,
  ALERT_TYPE_OK,
  ALERT_TYPE_TECHNICAL_HELP,
} from '@constants/alert/type'
import CopyButton from '@components/CopyButton.vue'

const alertMessages = {
  [ALERT_TYPE_MEDICAL_HELP]: [
    'MEDICAL HELP - J ai besoin d une assistance medicale',
    'MEDICAL HELP - I need medical help',
    'MEDICAL HELP - Necesito ayuda medica',
  ],
  [ALERT_TYPE_TECHNICAL_HELP]: [
    'ASSISTANCE - J ai besoin d assistance',
    'ASSISTANCE - I need assistance',
    'ASSISTANCE - Necesito asistencia',
  ],
  [ALERT_TYPE_OK]: [
    'OK - STOP ALERT - Annulation d alerte',
    'OK - STOP ALERT - Alert canceled',
    'OK - STOP ALERT - Alerta cancelada',
  ],
}

export default {
  name: 'ListLiveAlertContactMessage',

  components: {
    CopyButton,
  },

  props: {
    alertType: {
      type: String,
      required: true,
      validate: (v) =>
        [ALERT_TYPE_MEDICAL_HELP, ALERT_TYPE_TECHNICAL_HELP, ALERT_TYPE_OK].indexOf(v) !== -1,
    },
  },

  created() {
    this.alertMessages = alertMessages[this.alertType]
  },
}
</script>
