<template>
  <div class="box">
    <form novalidate="true" @submit.prevent="submit">
      <b-field
        label="Nom"
        :message="fields.name.error"
        :type="fields.name.error !== null ? 'is-danger' : ''"
      >
        <b-input v-model="fields.name.value" type="text" />
      </b-field>
      <b-field
        label="Type d'accès"
        :message="fields.accessType.error"
        :type="fields.accessType.error !== null ? 'is-danger' : ''"
      >
        <b-field>
          <b-radio-button v-model="fields.accessType.value" native-value="public">
            <span>Public</span>
          </b-radio-button>
          <b-radio-button v-model="fields.accessType.value" native-value="private">
            <span>Privé</span>
          </b-radio-button>
        </b-field>
      </b-field>
      <b-field
        label="Icône"
        :message="fields.icon.error"
        :type="fields.icon.error !== null ? 'is-danger' : ''"
      >
        <b-field grouped group-multiline>
          <b-radio-button
            v-for="icon in iconNames"
            :key="icon"
            v-model="fields.icon.value"
            :native-value="icon"
          >
            <span>
              <o-icon :name="icon" />
            </span>
          </b-radio-button>
        </b-field>
      </b-field>
      <b-field
        label="Couleur"
        :message="fields.color.error"
        :type="fields.color.error !== null ? 'is-danger' : ''"
      >
        <b-field grouped group-multiline>
          <b-radio-button
            v-for="color in colors"
            :key="color.value"
            v-model="fields.color.value"
            :native-value="color.value"
          >
            {{ color.label }}
          </b-radio-button>
        </b-field>
      </b-field>
      <hr />
      <b-field
        label="Validation de CP"
        :message="fields.useCheckpoint.error"
        :type="fields.useCheckpoint.error !== null ? 'is-danger' : ''"
      >
        <b-radio-button v-model="fields.useCheckpoint.value" :native-value="true">
          <span>Oui</span>
        </b-radio-button>
        <b-radio-button v-model="fields.useCheckpoint.value" :native-value="false">
          <span>Non</span>
        </b-radio-button>
      </b-field>
      <hr />
      <b-field>
        <div class="buttons">
          <b-button native-type="submit" type="is-primary" :loading="loading">Ajouter</b-button>
          <b-button
            type="is-danger"
            tag="router-link"
            :to="{ name: 'live.edit.category.list', params: { id: live.id } }"
          >
            Annuler
          </b-button>
        </div>
      </b-field>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'EditLiveAddCategoryTab',

  props: {
    live: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      error: null,
      fields: {
        name: {
          value: '',
          error: null,
        },
        accessType: {
          value: 'public',
          error: null,
        },
        useCheckpoint: {
          value: true,
          error: null,
        },
        icon: {
          value: '',
          error: null,
        },
        color: {
          value: '',
          error: null,
        },
      },
      iconNames: [
        'walking',
        'hiking',
        'bicycle',
        'motorcycle',
        'quad',
        'ssv',
        'car',
        'truck-pickup',
        'truck',
        'ambulance',
        'user',
        'user-doctor',
        'user-slash',
        'broom',
        'camera',
        'face-sad-tear',
        'plane',
        'sailboat',
        'helicopter',
      ],
      colors: [
        { value: 'blue', label: 'Bleu' },
        { value: 'gray', label: 'Gris' },
        { value: 'green', label: 'Vert' },
        { value: 'orange', label: 'Orange' },
        { value: 'pink', label: 'Rose' },
        { value: 'purple', label: 'Violet' },
        { value: 'red', label: 'Rouge' },
      ],
    }
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.loading = true
      this.error = null

      try {
        const liveCategory = await this.$services.liveCategoryService.create({
          liveId: this.live.id,
          name: this.fields.name.value,
          accessType: this.fields.accessType.value,
          icon: this.fields.icon.value,
          color: this.fields.color.value,
          useCheckpoint: this.fields.useCheckpoint.value,
        })
        this.addToastMessage({
          text: `La catégorie "${liveCategory.name}" a été créé.`,
          type: 'is-success',
        })
        this.$router.push({
          name: 'live.edit.category.list',
          params: { id: this.live.id },
        })
      } catch (err) {
        this.error = 'Une erreur interne est survenue.'
        console.error(err)
      }

      this.loading = false
    },
  },
}
</script>
