<template>
  <div class="columns is-multiline">
    <div class="column is-full">
      <b-message v-if="outdatedTrackers.length" type="is-danger">
        {{ outdatedTrackers.length }} balise{{ outdatedTrackers.length > 1 ? 's' : '' }} non à jour
      </b-message>
      <b-message v-else type="is-success"> Toutes les balises sont à jour </b-message>
    </div>
    <o-table :items="trackers" :draggable="false" class="column is-full" sticky-header>
      <b-table-column v-slot="{ row }" field="updated" label="Status" sortable>
        <b-icon v-if="row.updated" icon="check" type="is-success" />
        <b-icon v-else icon="times" type="is-danger" />
      </b-table-column>
      <b-table-column v-slot="{ row }" field="number" label="Balise" sortable>
        {{ row.number }}
      </b-table-column>
      <b-table-column v-slot="{ row }" field="vehicleNumber" label="Dossard" sortable>
        {{ row.vehicleNumber }}
      </b-table-column>
    </o-table>
    <div v-if="isSwitchInProgress && trackerBeingUpdated" class="column is-full">
      Mise à jour de la balise {{ trackerBeingUpdated.number }} en cours...
      <b-progress :value="progress.value" type="is-success" show-value>
        {{ progress.label }}
      </b-progress>
      <template v-if="estimatedTimeInSecondes >= 1">
        Temps estimé {{ estimatedTimeInSecondes }} seconde{{
          estimatedTimeInSecondes > 1 ? 's' : ''
        }}
      </template>
    </div>
    <div class="column is-full">
      <b-message v-if="banTimeInSeconds" type="is-danger">
        Limite de requêtes atteinte. Patientez
        {{ banTimeInSeconds }} seconde{{ banTimeInSeconds > 1 ? 's' : '' }}.
      </b-message>
      <b-button
        v-if="outdatedTrackers.length"
        :type="action.type"
        :loading="action.loading"
        :disabled="action.disabled"
        @click="action.handler"
      >
        {{ action.label }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OTable from '@components/Table.vue'
import { SPOT_TRACKER_TYPE } from '@constants/tracker/type'
import { TOO_MANY_REQUESTS_ERROR_TYPE } from '@services/api'

// limit to 120 calls/minutes. 1 call for login, 39 switches / minutes.

const RETRY_AFTER_IN_MS = 300000
const RESET_TIME_IN_MS = 60000
const MAX_SWITCH_PER_RESET_TIME = 39
const MINIMUM_TIME_BETWEEN_SWITCH = RESET_TIME_IN_MS / MAX_SWITCH_PER_RESET_TIME
const FINDMESPOT_END_OF_RATE_LIMIT = 'findmespotEndOfRateLimit'

export default {
  name: 'SwitchSpotTrackerProfiles',

  components: { OTable },

  props: {
    live: {
      type: Object,
      required: true,
    },
    liveSpotTrackerConfig: {
      type: Object,
      required: true,
    },
    liveVehicles: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      trackers: [],
      trackerBeingUpdated: null,
      loading: false,
      isSwitchInProgress: false,
      switchTimeoutId: null,
      rateLimitIntervalId: null,
      banTimeInSeconds: null,
      estimatedTimeInSecondes: 0,
    }
  },

  async mounted() {
    for (const vehicle of this.liveVehicles) {
      if (vehicle.trackers.length === 0) {
        continue
      }

      for (const tracker of vehicle.trackers) {
        if (tracker.type !== SPOT_TRACKER_TYPE) {
          continue
        }

        this.trackers.push({
          ...tracker,
          vehicleNumber: vehicle.number,
          updated:
            tracker.findmespotProfileId === this.liveSpotTrackerConfig.profileId &&
            tracker.findmespotDeviceName === `${tracker.number}-${vehicle.number}`,
        })
      }
    }
  },

  beforeDestroy() {
    this.abortSwitches()
    window.clearInterval(this.rateLimitIntervalId)
  },

  watch: {
    liveSpotTrackerConfig() {
      this.trackers = this.trackers.map((tracker) => ({
        ...tracker,
        updated:
          tracker.findmespotProfileId === this.liveSpotTrackerConfig.profileId &&
          tracker.findmespotDeviceName === `${tracker.number}-${tracker.vehicleNumber}`,
      }))
    },
  },

  computed: {
    outdatedTrackers() {
      return this.trackers.filter((tracker) => !tracker.updated)
    },

    progress() {
      return {
        value: ((this.trackers.length - this.outdatedTrackers.length) / this.trackers.length) * 100,
        label: `${this.trackers.length - this.outdatedTrackers.length}/${this.trackers.length}`,
      }
    },

    action() {
      return this.isSwitchInProgress
        ? {
            label: 'Annuler',
            type: 'is-danger',
            handler: this.abortSwitches,
            loading: false,
            disabled: false,
          }
        : {
            label: 'Mettre à jour',
            type: 'is-primary',
            handler: this.startSwitches,
            loading: this.loading,
            disabled: this.loading || this.banTimeInSeconds > 0,
          }
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async startSwitches() {
      this.loading = true
      this.isSwitchInProgress = true

      const outdatedTrackers = this.outdatedTrackers
      let switchIndex = 0

      this.estimatedTimeInSecondes = Math.max(
        0,
        Math.ceil((this.outdatedTrackers.length * MINIMUM_TIME_BETWEEN_SWITCH) / 1000),
      )

      const delaySwitches = async () => {
        if (switchIndex === outdatedTrackers.length || !this.isSwitchInProgress) {
          this.abortSwitches()
          return
        }

        window.clearTimeout(this.switchTimeoutId)

        const switchStartTime = Date.now()

        this.trackerBeingUpdated = outdatedTrackers[switchIndex]
        await this.switchProfile(this.trackerBeingUpdated)

        // Make sur to wait at least the minimum required between each switch
        const switchEndTime = Math.max(Date.now() - switchStartTime, MINIMUM_TIME_BETWEEN_SWITCH)

        // Calculate the next interval to wait before the next switch
        const nextInterval = Math.max(
          MINIMUM_TIME_BETWEEN_SWITCH - (switchEndTime - MINIMUM_TIME_BETWEEN_SWITCH),
          0,
        )

        this.estimatedTimeInSecondes = Math.max(
          0,
          Math.ceil(this.estimatedTimeInSecondes - MINIMUM_TIME_BETWEEN_SWITCH / 1000),
        )

        this.switchTimeoutId = setTimeout(async () => {
          switchIndex++
          await delaySwitches()
        }, nextInterval)
      }

      await delaySwitches()
    },

    abortSwitches() {
      window.clearTimeout(this.switchTimeoutId)

      this.loading = false
      this.isSwitchInProgress = false
      this.switchTimeoutId = null
    },

    async switchProfile(tracker) {
      try {
        try {
          const updatedSpotTracker = await this.$services.spotTrackerService.switchProfile({
            id: this.trackerBeingUpdated.id,
            liveId: this.live.id,
          })

          this.trackers = this.trackers.map((trk) => {
            if (tracker.id === trk.id) {
              return {
                ...trk,
                findmespotProfileId: updatedSpotTracker.findmespotProfileId,
                findmespotDeviceName: updatedSpotTracker.findmespotDeviceName,
                updated: true,
              }
            }

            return trk
          })
        } catch (err) {
          if (err.type === TOO_MANY_REQUESTS_ERROR_TYPE) {
            this.showRateLimit()
            this.abortSwitches()
          }
        }
      } catch (err) {
        console.error(err)
      }
    },

    showRateLimit() {
      window.clearInterval(this.rateLimitIntervalId)

      const now = Date.now()
      let retryAfterTime = Number(localStorage.getItem(FINDMESPOT_END_OF_RATE_LIMIT))

      if (!retryAfterTime || retryAfterTime - now > RETRY_AFTER_IN_MS) {
        retryAfterTime = now + RETRY_AFTER_IN_MS
        localStorage.setItem(FINDMESPOT_END_OF_RATE_LIMIT, retryAfterTime.toString())
      }

      if (now >= retryAfterTime) {
        this.resetRateLimit()

        return
      }

      this.banTimeInSeconds = Math.round((retryAfterTime - now) / 1000)

      this.rateLimitIntervalId = window.setInterval(() => {
        const currentTime = Date.now()
        if (currentTime >= retryAfterTime) {
          this.resetRateLimit()

          return
        }

        this.banTimeInSeconds = Math.round((retryAfterTime - currentTime) / 1000)
      }, 1000)
    },

    resetRateLimit() {
      this.banTimeInSeconds = 0

      window.clearInterval(this.rateLimitIntervalId)
      localStorage.removeItem(FINDMESPOT_END_OF_RATE_LIMIT)
    },
  },
}
</script>
